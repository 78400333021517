
class AuthHelperClass {


    constructor() {
        this.token = '';
    }



    async getToken() {
        const token = localStorage.getItem('token')

        return token;
    }

    async setToken(token) {
        this.token = token;

        localStorage.setItem('token', token)
    }

    async getUser() {
        const response = localStorage.getItem('user')

        let user;

        try {
            user = JSON.parse(response)
        } catch (error) {
            console.log('User Parse Error: ', error);
        }

        return user;
    }

    async setUser(user) {

        this.user = user;

        localStorage.setItem('user', JSON.stringify(user))
    }

}

export const AuthHelper = new AuthHelperClass()