import {
    CommonAPI
} from "./common/Common.api";
import {
    UserAPI
} from "./users/User.api";
import {
    AuthAPI
} from "./Auth.api";





export const UserAPIInstance = new UserAPI(process.env.VUE_APP_API)
export const CommonAPIInstance = new CommonAPI(process.env.VUE_APP_API)
export const AuthAPIInstance = new AuthAPI(process.env.VUE_APP_API)

