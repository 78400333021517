import {
  FormatterHelper
} from "../../../../../helpers/formatter.helper";
// import imageToBase64 from 'image-to-base64';
import {
  requestBase64
} from 'base64-img'
import isDarkColor from 'is-dark-color'
import color from 'color';



// const toBase64 = file => new Promise((resolve, reject) => {
//   const reader = new FileReader();
//   reader.readAsDataURL(file);
//   reader.onload = () => resolve(reader.result);
//   reader.onerror = error => reject(error);
// });

/*
 * Return the HTML representation of a node.
 */
export const compileHTML = async (task) => {



  console.log('task: ', task.name)

  const bodyImage = task.images && task.images.length ? task.images[0].urlResized ? task.images[0].urlResized :
    (await new Promise((resolve, reject) => requestBase64(task.images[0].src, (err, res, body) => err ? reject(err) : resolve(body)))).replace(' ', '') : undefined;


  return `

  <div class="node-body task ${task.classes?task.classes:''}" id="node-${task.id}" 
       style="background-color:${task.color}; 
              color:${task.color? isDarkColor(color(task.color).hex())?'white':'black':'black'};">

              <div class="task-actions">
              <div class="action new" title="Create new task"><i class="mdi mdi-plus-box-outline"></i></div>
              <div class="action edit" title="Edit task"><i class="mdi mdi-square-edit-outline"></i></div>
              <div class="action remove" title="Remove task"><i class="mdi mdi-trash-can-outline"></i></div>
              <div class="action link" title="Link task"><i class="mdi mdi-link-box-variant-outline"></i></div>
            </div>

  ${
    // avatar
    task.assignee && task.assignee.avatar ?
    '<div class="assignee" style="background-image:url('+ (await new Promise((resolve,reject)=>requestBase64(task.assignee.avatar,(err,res, body)=>err?reject(err):resolve(body)))).replace(' ','')   +');"></div>'
    :''
    }

    <div class="task-title  ${task.assignee && task.assignee.avatar ?'assign':''} ">
    [${task.simpleId} ]
    ${
      FormatterHelper.cutString(task.name,40)
      
      }
      
    </div>


    ${
      // Priority
      task.priority? `
      <div class="task-tag" style="color:${task.priority.iconColor};" title=${task.priority.name} ><i class="mdi ${task.priority.icon}"></i> <span class="tag-name"> ${task.priority.name} </span></div>
      
      `:
      ``
    }

    ${
      // Status
      task.status? `
      <div class="task-tag" style="color:${task.status.iconColor};" title=${task.status.name} ><i class="mdi ${task.status.icon}"></i> <span class="tag-name"> ${task.status.name} </span></div>
      
      `:
      ``
    }
    


   


      <div class="img-container">
      ${
        // body Image
        bodyImage ?
        '<div class="task-image" style="background-image:url('+ bodyImage +');"></div>'
        :''
        }

     </div>

   
  
  </div>`;
}