import Vue from 'vue'
import VueRouter from 'vue-router'
import SignIn from '../views/SignIn.vue'
import SignInTemplate from '../views/SignInTemplate.vue'


import {
  AuthAPIInstance
} from '../components/api'
import {
  store
} from '../main'



Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: SignInTemplate,
    children: [{
        path: '/',
        name: 'SignIn',
        component: SignIn,
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router
  .beforeEach(async (to, from, next) => {
    if (to.meta.auth) {
      const [token, user] = await Promise.all([
        store.dispatch("MainStore/getToken"),
        store.dispatch("MainStore/getUser"),
      ]);

      if (!token || !user) return next({
        path: '/sign-in',
        replace: true
      });

      try {
        await AuthAPIInstance.validateToken(token);
        console.log("TOKEN VALID");

        return next();

      } catch (error) {
        console.log("ERROR", error);
        store.dispatch("MainStore/setAuth", {});
        return next({
          path: '/sign-in',
          replace: true
        });

      }

    }

    return next();
  });

export default router