export class FormatterHelper {



    static price(format, currency = 'USD') {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency
        }).format(format / 100)
    }


    static priceCustom(format, n) {
        return Number(format).toFixed(n)
    }


    /**
     * 
     * @param {number} number 
     * @returns 
     */
    static timeDuration(number, measure = 's') {
        const pSeconds = Number(number);
        let divider = 1;
        if (measure === 's')
            divider = 1;

        if (measure === 'm')
            divider = 60;

        if (measure === 'h')
            divider = 3600;

        const d = Math.floor(pSeconds / ((3600 / divider) * 24));
        const h = Math.floor(pSeconds % ((3600 / divider) * 24) / (3600 / divider));
        const m = Math.floor(pSeconds % ((3600 / divider) / (60 / divider)));
        const s = Math.floor(pSeconds % (60 / divider));

        const dDisplay = d > 0 ? d + (d == 1 ? " d, " : " d, ") : "";
        const hDisplay = h > 0 ? h + (h == 1 ? " h, " : " h, ") : "";
        const mDisplay = m > 0 ? m + (m == 1 ? " m, " : " m, ") : "";
        const sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";


        return dDisplay + hDisplay + mDisplay + sDisplay;
    }


    static cutString(string, numberOfChars = 100) {
        if (!string)
            return '-';

        return String(string).length < numberOfChars ? String(string) : String(string).slice(0, numberOfChars - 3) + '...';

    }
}