export class BoardActions {


    constructor() {
        this.handlers = new Map()
    }


    /**
     * 
     * @param {string} action 
     * @param {function | function[]} subscriber 
     */
    subscribe(action, subscriber) {
        let handlers = this.handlers.get(action);

        if (!handlers)
            this.handlers.set(action, Array.isArray(subscriber) ? subscriber : [subscriber])
        else {
            if (Array.isArray(subscriber))
                subscriber.forEach(sub => handlers.push(sub));
            else
                handlers.push(subscriber);

            this.handlers.set(action, handlers);
        }

    }



    emit(action, payload) {
        const handlers = this.handlers.get(action);

        for (const handler of handlers) {
            handler(payload)
        }
    }
}