import {
    MindMap
} from "./utils/mindmap";
import {
    v4 as uuidv4
} from "uuid";
import uniqolor from 'uniqolor';
import {
    Task
} from "./Task";
import {
    Connection
} from "./Connection";
import {
    BoardActions
} from "./BoardActions";

export class Board {


    constructor({
        id,
        color,
        name,
        connections,
        simpleId,
        tasks
    } = {}) {
        if (!name)
            throw new Error('Board name is required!')

        this.simpleId = simpleId;
        this.id = id || uuidv4();
        this.color = color || uniqolor.random().color;
        this.name = name;

        /**
         * @type {Connection[]}
         */
        this.connections = connections || [];
        /**
         * @type {Task[]}
         */
        this.tasks = tasks || [];

        this.mindMap = new MindMap(this.id, {
            nodes: this.tasks,
            connections: this.connections
        })

        this.actions = new BoardActions();
    }


    async render() {
        await this.mindMap.renderMap()
    }



    /**
     * 
     * @param {*} taskProps 
     * @param {Task | string} parent 
     * @returns  {Task}
     */
    addTask(taskProps = {}, parentOrId) {

        taskProps.simpleId = this.simpleId + '-' + (this.tasks.length + 1)
        taskProps.settings = Task.DefaultSettings;
        let newTask;

        if (parentOrId) {

            const parent = parentOrId instanceof Task ? parentOrId : this.getTaskById(parentOrId);

            taskProps.color = parent.color;
            taskProps.settings = {
                ...Task.DefaultSettings,
                ...parent.settings
            }

            newTask = new Task(taskProps);

            this.addConnection(parent, newTask);

        } else
            newTask = new Task(taskProps);


        this.tasks.push(newTask);

        return newTask;
    }


    /**
     * 
     * @param {Task | string} sourceOrId 
     * @param {Task | string} targetOrId 
     * @returns {Connection}
     */
    addConnection(sourceOrId, targetOrId) {

        const source = sourceOrId instanceof Task ? sourceOrId : this.getTaskById(sourceOrId);
        const target = targetOrId instanceof Task ? targetOrId : this.getTaskById(targetOrId);


        const newConnection = new Connection({
            source: source.id,
            target: target.id,
            color: source.color
        })

        this.connections.push(newConnection);

        return newConnection;
    }


    /**
     * 
     * 
     * 
     * @param {Task} task 
     */
    updateTask(task) {

        const tasksToUpdate = [task];
        // const connectionToUpdate = [];

        // for (let i = 0; i < this.connections.length; i++) {
        //     const conn = this.connections[i];

        //     const possibleTask = tasksToUpdate.findIndex(t => t.id === conn.source)
        //     if (possibleTask) {
        //         i = 0;
        //         tasksToUpdate.push(possibleTask);

        //         const targetTask = this.tasks.find(t => t.id === conn.target);

        //         if (!targetTask.settings.NESTED_UPDATE_StatusChangeProtection)
        //             targetTask.status = task.status;

        //         if (!targetTask.settings.NESTED_UPDATE_ColorChangeNestedProtection) {
        //             targetTask.color = task.color;
        //             conn.color = task.color;
        //         }
        //     }

        // }


        for (let i = 0; i < tasksToUpdate.length; i++) {
            const taskToCompare = tasksToUpdate[i];

            const connections = this.connections.filter(conn => conn.source === taskToCompare.id);

            connections.forEach(conn => {


                const targetTask = this.tasks.find(t => t.id === conn.target)

                if (!targetTask.settings.NESTED_UPDATE_StatusChangeProtection)
                    targetTask.status = task.status;

                if (!targetTask.settings.NESTED_UPDATE_ColorChangeNestedProtection) {
                    targetTask.color = task.color;
                }

                conn.color = task.color;



                if (!targetTask.settings.NESTED_UPDATE_StatusChangeProtection ||
                    !targetTask.settings.NESTED_UPDATE_ColorChangeNestedProtection
                )
                    tasksToUpdate.push(targetTask);


            });

        }

    }


    /**
     * 
     * @param {string | Task} taskOrId 
     */
    removeTask(taskOrId) {
        let taskIndex = -1;


        let taskId = '';
        if (taskOrId instanceof Task || (typeof taskOrId === 'object' && taskOrId.id)) taskId = taskOrId.id;
        else
            taskId = taskOrId;

        // first find and remove all possible connections
        const connectionIndexesToRemove = this.connections
            .map((el, i) =>
                el.source === taskId || el.target === taskId ?
                i :
                undefined)
            .filter(el => el || el === 0)



        taskIndex = this.tasks.findIndex(task => task.id === taskId)


        for (let i = 0; i < connectionIndexesToRemove.length; i++) {

            this.connections.splice(connectionIndexesToRemove[i] - i, 1);
        }


        if (taskIndex !== -1)
            this.tasks.splice(taskIndex, 1);

    }

    /**
     * 
     * @param {string | Connection} connectionOrId 
     */
    removeConnection(connectionOrId) {
        let connectionIndex = -1;


        if (connectionOrId instanceof Connection || (typeof connectionOrId === 'object' && connectionOrId.id)) {
            connectionIndex = this.connections.findIndex(task => task.id === connectionOrId.id)
        } else {
            connectionIndex = this.connections.findIndex(task => task.id === connectionOrId)
        }

        if (connectionIndex !== -1)
            this.connections.splice(connectionIndex, 1);
    }


    getTaskById(id) {
        const task = this.tasks.find(t => t.id === id)
        return task
    }


    getConnectionById(id) {
        const connection = this.connections.find(c => c.id === id)
        return connection
    }


    // STATS

    getSelectedTasks({
        x,
        y,
        width,
        height
    } = {}) {
        return this.tasks.filter(t => {

            if (
                t.d3Node.fx > parseFloat(x) &&
                t.d3Node.fy > parseFloat(y) &&
                t.d3Node.fx < parseFloat(x) + parseFloat(width) &&
                t.d3Node.fy < parseFloat(y) + parseFloat(height)
            )
                return true
            else
                return false
        });
    }


    /**
     * 
     * @param {Task[]} selectedTasks 
     * @returns 
     */
    statsByStatus(selectedTasks = []) {

        const stats = selectedTasks.reduce((acc, curr) => {

            const target = curr.status ? curr.status.name : 'N/A'

            const existedStats = acc.find(st => st.name === target)
            if (existedStats)
                existedStats.share += 1
            else
                acc.push({
                    name: target,
                    share: 1
                });

            return acc;

        }, [])

        return stats;
    }

    /**
     * 
     * @param {Task[]} selectedTasks 
     * @returns 
     */
    statsByPriority(selectedTasks = []) {

        const stats = selectedTasks.reduce((acc, curr) => {
            const target = curr.priority ? curr.priority.name : 'N/A'

            const existedStats = acc.find(st => st.name === target)
            if (existedStats)
                existedStats.share += 1
            else
                acc.push({
                    name: target,
                    share: 1
                });

            return acc;

        }, [])

        return stats;
    }



    toJSON() {
        return {
            id: this.id,
            name: this.name,
            color: this.color,
            simpleId: this.simpleId,
            connections: this.connections.map(conn => conn.toJSON()),
            tasks: this.tasks.map(task => task.toJSON())
        }
    }
}