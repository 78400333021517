import {
  drag,
  event,
  zoom
} from "d3";

// /**
//  * Bind data to a <TAG>, inside a G element, inside the given root element.
//  * Root is a D3 selection, data is an object or array, tag is a string.
//  */
// const bindData = (root, data, tag) =>
//   root
//   .append("g")
//   .selectAll(tag)
//   .data(data)
//   .enter()
//   .append(tag);

/**
 * Bind connections to PATH tags on the given SVG
 */
export const d3Connections = (svg, connections) => {
  return svg
    .append("g")
    .selectAll("path")
    .data(connections)
    .enter()
    .append("path")
    .attr("class", "mindmap-connection")
    .attr('stroke', (node) => node.color ? node.color : "#757575");
}

/**
 * Bind nodes to FOREIGNOBJECT tags on the given SVG,
 * and set dimensions and html.
 */
export const d3Nodes = (svg, nodes) => {
  const selection = svg
    .append("g")
    .selectAll("foreignObject")
    .data(nodes)
    .enter()
    .append("foreignObject")
    .attr("class", "mindmap-node")
    .attr("width", node => node.width)
    .attr("height", node => node.height)
    .html(node => node.html);

  return selection;
};

/**
 * Callback for forceSimulation tick event.
 */
export const onTick = (conns, nodes) => {
  const d = conn => [
    "M",
    conn.source.x,
    ",",
    conn.source.y,
    " ",
    "C",
    (conn.source.x + conn.target.x) / 2,
    ",",
    conn.source.y,
    " ",
    (conn.source.x + conn.target.x) / 2,
    ",",
    conn.target.y,
    " ",
    conn.target.x,
    ",",
    conn.target.y
  ].join("");

  // Set the connections path.
  conns.attr("d", d);

  // Set nodes position.
  nodes
    .attr("x", node => node.x - node.width / 2)
    .attr("y", node => node.y - node.height / 2);
};

/*
 * Return drag behavior to use on d3.selection.call().
 */
export const d3Drag = (simulation) => {


  const dragStart = node => {
    if (!event.active) {
      simulation.alphaTarget(0.2).restart();
    }

    node.fx = node.x;
    node.fy = node.y;
  };

  const dragged = node => {
    node.fx = event.x;
    node.fy = event.y;
  };

  const dragEnd = () => {
    if (!event.active) {
      simulation.alphaTarget(0);
    }
  };

  return drag()
    .on("start", dragStart)
    .on("drag", dragged)
    .on("end", dragEnd);
};

/*
 * Return pan and zoom behavior to use on d3.selection.call().
 */
export const d3PanZoom = el =>
  zoom()
  .scaleExtent([0.1, 5])
  .on("zoom", () =>
    el.selectAll("svg > g").attr("transform", event.transform)
  );

/*
 * d3 node click event
 */
export const d3NodeClick = () => {
  event.stopPropagation();
  let target = event.target;

  console.log('target.className', target.className)



  switch (target.className) {
    case "mdi mdi-plus-box-outline":
      console.log('add')
      return {
        action: "add", event
      };
    case "mdi mdi-square-edit-outline":
      console.log('edit')
      return {
        action: "edit", event
      };
    case "mdi mdi-trash-can-outline":
      console.log('remove')
      return {
        action: "remove", event
      };
    case "mdi mdi-link-box-variant-outline":
      console.log('link')
      return {
        action: "link", event
      };

    default:
      console.log('default')
      return {
        action: "click", event
      };

  }
};