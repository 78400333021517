var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-in-form-container",class:{ minified: _vm.minified }},[_c('h2',{staticClass:"product-name"},[_vm._v("Mimtask")]),_c('MessageHistory',{style:({ height: '100%' }),attrs:{"value":_vm.messages}}),_c('MessageBox',{attrs:{"label":_vm.messages && _vm.messages.length && _vm.messages[_vm.messages.length - 1].inputHint
        ? _vm.messages[_vm.messages.length - 1].inputHint
        : 'Message',"type":_vm.messages &&
      _vm.messages.length &&
      _vm.messages[_vm.messages.length - 1].inputHint &&
      _vm.messages[_vm.messages.length - 1].inputHint === 'Password'
        ? 'password'
        : 'text',"dark":!_vm.minified ? true : _vm.dark},on:{"onMessage":_vm.onMessage}}),_c('v-fade-transition')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }