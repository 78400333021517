import {
    Board
} from "./Board";
import {
    Task
} from "./Task";
import {
    Connection
} from "./Connection";
import _ from "lodash";




export class Storage {



    constructor(type = 'localstorage') {
        this.type = type


        /**
         * @type {Board[]}
         */
        this.boards = [];
    }



    load() {
        try {
            const jsonBoards = localStorage.getItem('boards');
            const parsed = JSON.parse(jsonBoards)


            this.boards = parsed.map(par => {
                const tasks = par.tasks.map(task => new Task(task))
                const connections = par.connections.map(conn => new Connection(conn))

                return new Board({

                    ...par,
                    tasks,
                    connections
                })
            });

        } catch (error) {
            console.log('Impossible to parse boards. It seems like you\'re a new user', error)

            this.addBoard();

            this.save();
        }

        return this;
    }


    save() {
        localStorage.setItem('boards', JSON.stringify(this.boards.map(board => board.toJSON())));
    }

    startSaving() {
        this.interval = setInterval(() => {
            localStorage.setItem('boards', JSON.stringify(this.boards.map(board => board.toJSON())));
        }, 5000);
    }

    stopSaving() {
        clearInterval(this.interval);
    }


    /**
     * 
     * @returns {Storage}
     */
    loadMocked() {
        // for (let i = 0; i < 3; i++) {
        //     const newBoard = new Board({
        //         name: `Board #${i+1}`
        //     });

        //     newBoard.tasks = map.nodes.map(n => new Task(n))
        //     newBoard.connections = map.connections.map(c => new Connection(c))

        //     this.boards.push(newBoard);
        // }

        return this;
    }


    addBoard(name = `Default Board ${this.boards.length}`) {

        const [firstWord, secondWord] = name.replace(/\s+/g, ' ').trim().split(' ');

        const shortCode = secondWord ? _.capitalize(firstWord[0] + secondWord[0]) : _.capitalize(firstWord[0] + firstWord[firstWord.length - 1])

        const newBoard = new Board({
            simpleId: shortCode,
            name: name
        });

        const task1 = newBoard.addTask({
            name: 'Initial Task'
        });

        newBoard.addTask({
            name: 'Another Connected Task'
        }, task1);


        this.boards.push(newBoard);


        return newBoard
    }


    /**
     * 
     * @param {Board} board 
     */
    dropBoard(board) {

        const index = this.boards.findIndex(br => br.id === board.id);

        this.boards.splice(index, 1);

        this.save();

        return index;
    }

}