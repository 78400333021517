import {
    v4 as uuidv4
} from "uuid";
import uniqolor from 'uniqolor';

export class Connection {


    constructor({
        id,
        source,
        target,
        type,
        color
    }) {
        this.id = id || uuidv4()
        this.source = source;
        this.target = target
        this.type = type || 'default';
        this.color = color || uniqolor.random().color;
    }



    toJSON() {
        return {
            id: this.id,
            color: this.color,
            source: this.source,
            target: this.target
        }
    }


    async compile() {

      
        return {
            id: this.id,
            source: this.source,
            target: this.target,
            color: this.color
        }
    }

}