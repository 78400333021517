<template>
  <v-select
    v-bind="$attrs"
    :value="value"
    @input="$emit('input', $event)"
    :items="items"
    clearable
    cache-items
    item-text="name"
    item-value="value"
    class="pa-0 ma-0 selector-default"
    :menu-props="{ dark: $attrs.dark }"
  >
    <template v-slot:append-outer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="selector-default-help-icon"
            color="grey"
            size="18"
            v-bind="attrs"
            v-on="on"
          >
            mdi-help
          </v-icon>
        </template>
        <span>{{ helpText }}</span>
      </v-tooltip>
    </template>

    <template v-slot:selection="data">
      <v-chip label v-bind="data.attrs">
        <v-icon
          class="mr-2"
          :color="data.item.iconColor ? data.item.iconColor : 'primary'"
          >{{ data.item.icon }}</v-icon
        >
        <span class="selection">
          {{ data.item.name }}
        </span>
      </v-chip>
    </template>

    <template v-slot:item="data">
      <v-list-item-icon v-if="data.item.icon">
        <v-icon
          :color="data.item.iconColor ? data.item.iconColor : 'primary'"
          >{{ data.item.icon }}</v-icon
        >
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-html="data.item.name"></v-list-item-title>
        <v-list-item-subtitle
          v-if="data.item.description"
          v-html="data.item.description"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-select>
</template>
  
  
  <script>
export default {
  props: {
    value: {},
    items: {
      type: Array,
      default: () => [],
    },

    helpText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.selector-default {
  align-items: flex-end;
  .selector-default-help-icon {
    margin-bottom: 24px;
  }

  // .selection {
  //   font-size: 22px;
  //   margin-top: 4px;
  //   margin-left: 4px;
  //   color: black;
  // }
}
</style>