<template>
  <div class="mind-map-container" :class="{ light: !dark }">
    <h2 v-if="pVisible" class="board-name" :class="{ light: !dark }">
      {{ board.name }}
    </h2>
    <svg
      v-if="pVisible"
      :id="board.id"
      class="mindmap-svg"
      xmlns="http://www.w3.org/2000/svg"
      :class="{ light: !dark }"
    ></svg>

    <svg
      v-if="pVisible"
      :id="`${board.id}-minimap`"
      class="minimap-container"
      xmlns="http://www.w3.org/2000/svg"
      :class="{ light: !dark }"
    ></svg>

    <v-dialog
      transition="dialog-bottom-transition"
      v-model="drawer"
      content-class="task-details"
      :dark="dark"
      :fullscreen="useFullScreen"
      hide-overlay
      max-width="1200px"
    >
      <template v-slot:default="{}">
        <v-card class="details-card">
          <v-toolbar dark :color="selectedTask.color || 'primary'">
            <v-btn icon dark color="#757575" @click="drawer = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ selectedTask.name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn color="#757575" text @click="onSave">Save</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <TaskForm
              :dark="dark"
              ref="taskForm"
              v-model="selectedTask"
            ></TaskForm>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>



<script>
import { MindMap } from "./lib/utils/mindmap";

// import d3ToPng from "d3-svg-to-png";
import { BotInstance } from "../../../../views/bot";
import { saveSvgAsPng } from "save-svg-as-png";
import TaskForm from "./TaskForm.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    board: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      mindmap: new MindMap(this.board),
      selectedTask: undefined,

      line: undefined,
      pt: undefined,

      linking: false,
      newTask: {
        name: "",
        description: "",
        priority: {},
        status: {},
      },
      pVisible: this.visible,
    };
  },
  computed: {
    useFullScreen: function () {
      return window.innerWidth < 800 ? true : false;
    },
  },
  components: {
    TaskForm,
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.mindmap.render();

      this.mindmap.subscribe("onTaskClicked", ({ task }) => {
        if (this.linking) {
          this.board.addConnection(this.linking.id, task.id);

          this.resetLinkingOnClick();
          this.mindmap.render(task.id);
        } else {
          const selectedTask = this.board.getTaskById(task.id);

          this.selectedTask = selectedTask;

          this.drawer = true;
        }
      });

      this.mindmap.subscribe("onTaskAdd", ({ task }) => {
        const newTask = this.board.addTask(
          {
            name: "New Task",
          },
          task.id
        );

        this.mindmap.render(newTask.id);
      });

      this.mindmap.subscribe("onTaskRemove", ({ task }) => {
        this.board.removeTask(task.id);

        // task because we need x and y from removed task
        this.mindmap.render(task);
      });

      this.mindmap.subscribe("onTaskLink", ({ task, event }) => {
        this.linking = task;

        console.log("task: ", task);

        console.log("event.clientX: ", event.clientX);
        this.line = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "line"
        );
        this.line.setAttribute("id", "linking-line");

        this.line.setAttribute("stroke", "black");

        // Find your root SVG element
        const svg = document.getElementById(this.board.id);
        this.pt = svg.createSVGPoint();

        this.pt.x = event.clientX;
        this.pt.y = event.clientY;
        const cursorPoint = this.pt.matrixTransform(
          svg.getScreenCTM().inverse()
        );

        this.line.setAttribute("x1", cursorPoint.x);
        this.line.setAttribute("y1", cursorPoint.y);

        this.line.setAttribute("x2", cursorPoint.x);
        this.line.setAttribute("y2", cursorPoint.y);

        svg.append(this.line);

        // Create an SVGPoint for future math

        document.addEventListener("click", this.resetLinkingOnClick, false);
        document.addEventListener(
          "mousemove",
          this.addDestinationLinePoints,
          false
        );
      });

      BotInstance.subscribe("onDownload", () => this.download());
    },
    resetLinkingOnClick() {
      console.log(
        "this.addDestinationLinePoints: ",
        this.addDestinationLinePoints
      );
      document.removeEventListener("click", this.resetLinkingOnClick);
      document.removeEventListener("mousemove", this.addDestinationLinePoints);

      document.getElementById("linking-line").remove();
      this.linking = undefined;
    },
    addDestinationLinePoints(ev) {
      const svg = document.getElementById(this.board.id);

      // document.getElementById(this.board.id).append(this.line);
      this.pt.x = ev.clientX;
      this.pt.y = ev.clientY;
      const cursorPoint = this.pt.matrixTransform(svg.getScreenCTM().inverse());

      this.line.setAttribute("x2", cursorPoint.x);
      this.line.setAttribute("y2", cursorPoint.y - 20);
    },
    onSave() {
      this.drawer = false;

      this.board.updateTask(this.selectedTask);

      this.mindmap.render(this.selectedTask.id);
      // this.$refs.taskForm.reset();
    },
    onClose() {
      this.drawer = !this.drawer;
    },
    async download() {
      saveSvgAsPng(
        document.getElementById(this.board.id),
        `${this.board.name}--tasks.png`,
        {
          scale: 1,
          height: 1080,
          width: 1920,
          top: 0,
          left: 0,
        }
      );
    },
  },
  watch: {
    visible: {
      handler(newVal) {
        if (newVal) {
          this.pVisible = true;
          console.log("INIT BOARD: ", this.board);
          setTimeout(() => {
            this.init();
          }, 200);
        } else {
          setTimeout(() => {
            this.pVisible = false;
          }, 500);
        }
      },
    },
  },
};
</script>


<style lang="scss">
.details-card {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(15px);
  border-radius: 12px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100vh;
}

#acab {
  position: fixed; /* Floating above */
  transition: transform 0.23s; /* Sticking effect */
  pointer-events: none; /* Allow clicking trough the div */
}
</style>