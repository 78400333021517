import {
    v4 as uuidv4
} from "uuid";


class Bot {


    constructor() {
        this.DEFAULT_ANSWERS = [
            "I'm sorry, I don't have an answer to that right now.",
            "Hmm, I'm not quite sure what you're asking. Can you please rephrase your question?",
            "That's a good question. Unfortunately, I don't have the answer at the moment.",
            "I'm afraid I don't have the expertise to answer that question.",
            "It seems I don't have the knowledge to help you with that right now.",
            "I'm sorry, my knowledge is limited and I don't have an answer for that.",
            "I don't want to misinform you, so let me check on that and get back to you.",
            "I'm still learning and developing my skills, so I don't have an answer to your question yet.",
            "I wish I could help you, but that is outside of my capabilities at the moment.",
            "I apologize, but that's beyond my area of expertise."
        ]

        this.DEFAULT_SUCCESS = [
            "Understood, your request has been processed.",
            "Got it, I've taken care of that for you.",
            "Copy that, I have completed your request.",
            "Roger that, your task has been accomplished.",
            "Affirmative, your request has been fulfilled.",
            "Ok, consider it done.",
            "Noted, I have completed the task as requested.",
            "Alright, I've handled that for you.",
            "Message received, your request has been processed.",
            "Ok, your task has been completed."
        ];



        this.DEFAULT_INIT = [
            "Hello! I'm the Mim-bot, your trusty task assistant. Ready to get started?",
            "Hey there, I'm the Mim-bot, here to lend a hand with whatever you need. Let's log in first!",
            "Greetings! I'm the Mim-bot, your go-to for daily tasks and more. Let's get you signed in!",
            "Welcome! I'm the Mim-bot, your personal helper for all your needs. Just sign in to get started!",
            "Hi! I'm the Mim-bot, your friendly task companion. Let's start by getting you logged in!"
        ]


        this.DEFAULT_LOGIN = [
            "Welcome back! Glad to see you again!",
            "Hey there! Nice to see you again!",
            "Good to have you back! Glad to see you again!",
            "Welcome back! It's great to see you again!",
            "Hey! It's good to see you again. Welcome back!"
        ]

        this.DEFAULT_BYE = [
            "Thanks for stopping by! Can't wait to see you again soon!",
            "It was great having you here! Looking forward to seeing you soon!",
            "Thank you for visiting us! Hope to see you again soon!",
            "Goodbye for now! Looking forward to your next visit!",
            "See you soon! Thank you for choosing us!"
        ];


        this.email = ''
        this.password = ''

        this.actions = new Map();
    }


    subscribe(action, handler) {
        this.actions.set(action, handler)
    }


    emit(action, payload = {}) {
        const handler = this.actions.get(action);

        if (handler) {
            handler(payload)
        }
    }

    fakeMessage({
        init,
        prev,
        command
    } = {}) {

        console.log('FAKE MESSAGE')

        const newMessage = {
            id: uuidv4(),
            loading: true,
            isMe: false,
            isBot: true,
            message: init ? this.DEFAULT_INIT[this.getRandomInt(0, this.DEFAULT_INIT.length - 1)] : this.DEFAULT_ANSWERS[this.getRandomInt(0, this.DEFAULT_ANSWERS.length - 1)],
            inputHint: init ? 'E-mail' : 'Message'
        }


        if (prev && prev.type === 'password') {
            newMessage.message = this.DEFAULT_LOGIN[this.getRandomInt(0, this.DEFAULT_LOGIN.length - 1)];
            this.password = prev.message;
            newMessage.inputHint = 'Message';
            if (this.email && this.password)
                this.emit('onSignIn', {})
        }




        this.parseCommand(newMessage, command ? {
            command
        } : this.identifyCommand(prev))






        this.emit('onMessage', newMessage)



        setTimeout(function () {
            newMessage.loading = false

        }, 0 + (Math.random() * 20) * 10);

    }


    parseCommand(message, {
        command,
        props
    } = {}) {
        switch (command) {
            case 'overview':
                message.message = 'Mimtask this is a new platform to orginize your daily work with tasks, planning and activities. We want to bring more transparency into your processes and that\'s why we created this app. We believe, that ability to see the big picture is the most important ability in the current modern world. '
                break;

            case 'about':
                message.message = `Are you tired of having a notebook lying around next to your work machine? Do you find it frustrating to fill out numerous forms in big task management systems? We understand your pain, and that's why we've developed a solution just for you.

                Introducing Mimtask, the latest and most user-friendly task management system on the market. With Mimtask, managing your tasks has never been easier. Our system is designed to help you visualize your progress and plans in a simple and intuitive way.
                
                Say goodbye to clunky task managers and hello to Mimtask - the easy and effective way to manage your tasks!`

                break;

            case 'settings':
                message.message = 'Ok, I got you, will open settings for you :) ';
                this.emit('onSettings', message)

                break;

            case 'download':
                message.message = this.DEFAULT_SUCCESS[this.getRandomInt(0, this.DEFAULT_SUCCESS.length - 1)];
                this.emit('onDownload', message)

                break;
            case 'create_board':
                message.message = this.DEFAULT_SUCCESS[this.getRandomInt(0, this.DEFAULT_SUCCESS.length - 1)];
                this.emit('onBoardCreation', {
                    message,
                    props
                })

                break;
            case 'email': {
                message.message = 'Perfect, now provide your password';
                message.inputHint = 'Password';
                this.email = props.email

                break;
            }

            case 'logout': {
                message.message = this.DEFAULT_BYE[this.getRandomInt(0, this.DEFAULT_BYE.length - 1)];
                this.password = undefined;
                this.email = undefined;
                this.emit('onSignOut', {})
                break;
            }


            default:
                break;
        }

        return message

    }

    identifyCommand(prev) {


        if (prev && prev.message && String(prev.message)
            .toLowerCase()
            .match(
                /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/ig
            )) {
            const email = String(prev.message)
                .toLowerCase()
                .match(
                    /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/ig
                )[0];

            return {
                command: 'email',
                props: {
                    email
                }
            }

        }


        if (prev && prev.message && String(prev.message)
            .toLowerCase()
            .match(/export|download/ig) && this.email && this.password) {

            return {
                command: 'download',
                props: {}
            }
        }

        if (prev && prev.message && String(prev.message)
            .toLowerCase()
            .match(/create board|create new board/ig)) {

            return {
                command: 'create_board',
                props: {
                    name: this.getStringBetween(prev.message, 'name: ', '$')
                }
            }

        }

        if (prev && prev.message && String(prev.message)
            .toLowerCase()
            .match(/light mode|dark mode|settings|config/ig)) {

            return {
                command: 'settings'
            }

        }

        if (prev && prev.message && String(prev.message)
            .toLowerCase()
            .match(/logout|exit|sign out|goodbye/ig)) {
            return {
                command: 'logout'
            }

        }


        return {};
    }


    getStringBetween(str, start, end) {
        const result = str.match(new RegExp(start + "(.*)" + end));

        return result[1];
    }


    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }


}


export const BotInstance = new Bot();