

<template>
  <div class="sign-in-form-container" :class="{ minified }">
    <!-- <v-img class="logo" src="/logo.png"> </v-img> -->

    <h2 class="product-name">Mimtask</h2>
    <MessageHistory
      :style="{ height: '100%' }"
      :value="messages"
    ></MessageHistory>

    <MessageBox
      @onMessage="onMessage"
      :label="
        messages && messages.length && messages[messages.length - 1].inputHint
          ? messages[messages.length - 1].inputHint
          : 'Message'
      "
      :type="
        messages &&
        messages.length &&
        messages[messages.length - 1].inputHint &&
        messages[messages.length - 1].inputHint === 'Password'
          ? 'password'
          : 'text'
      "
      :dark="!minified ? true : dark"
    ></MessageBox>
    <v-fade-transition> </v-fade-transition>
  </div>
</template>



<script>
import MessageBox from "../components/wad/molecules/common/MessageBox.vue";
import MessageHistory from "../components/wad/molecules/common/MessageHistory.vue";
import { BotInstance } from "./bot";

// import SignInFormVue from "../components/wad/molecules/signIn/SignInForm.vue";

export default {
  props: {
    minified: {},
    dark: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    signInCompleted: false,

    selectedPortal: null,
    showSplash: false,
    messages: [],
    Bot: BotInstance,
    replyTimeout: undefined,
  }),
  mounted() {
    BotInstance.subscribe("onMessage", (message) => {
      this.messages.push(message);
    });

    setTimeout(() => {
      BotInstance.fakeMessage({ init: true });
    }, 1000);
  },
  components: {
    // SignInFormVue,
    MessageBox,
    MessageHistory,
  },
  methods: {
    onMessage(msg) {
      this.messages.push(msg);

      if (this.replyTimeout) clearTimeout(this.replyTimeout);

      this.replyTimeout = setTimeout(() => {
        BotInstance.fakeMessage({
          prev: msg,
        });
      }, 1000);
    },
    async signIn(credentials) {
      try {
        await this.$store.dispatch("MainStore/logIn", credentials);
      } catch (error) {
        this.$router.push("/sign-in");
      }
    },

    async signInGoogle(credentials) {
      try {
        await this.$store.dispatch("MainStore/googleAuth", credentials);
      } catch (error) {
        console.log("Error: ", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-finish {
  width: 100% !important;
}

.portal-loading-finish {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: white;
  z-index: 9;
}

.portal-loading-finish.open-portal {
  animation: circle 3s;
  display: block;
}

@keyframes circle {
  0% {
    clip-path: circle(10%);
  }
  50% {
    clip-path: circle(45%);
  }
  100% {
    clip-path: circle(100%);
  }
}

.sign-in-form-container {
  // background: linear-gradient(to bottom, #000, transparent);
  // backdrop-filter: blur(15px);
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
  z-index: 4;

  &.minified {
    transform: translate(-50%, -720px);
    width: 80%;
  }

  @media only screen and (max-width: 1000px) {
    &.minified {
      width: 100%;
    }
  }

  @media only screen and (max-height: 900px) {
    &.minified {
      transform: translate(-50%, -665px);
      width: 100%;
    }
  }

  @media only screen and (max-height: 825px) {
    &.minified {
      transform: translate(-50%, -625px);
      width: 100%;
    }
  }

  @media only screen and (max-height: 750px) {
    &.minified {
      transform: translate(-50%, -605px);
      width: 100%;
    }
  }

  @media only screen and (max-height: 700px) {
    &.minified {
      transform: translate(-50%, -585px);
      width: 100%;
    }
  }

  padding: 10px;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 40%;
  transform: translate(-50%, -80%);
  border-radius: 20px;
  overflow: hidden;
  // -webkit-transition: width 1s ease-in-out;
  // -moz-transition: width 1s ease-in-out;
  // -o-transition: width 1s ease-in-out;
  // transition: width 1s ease-in-out;

  // min-height: 60px;

  height: 400px;

  .sign-in-tools {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 40px;
    position: absolute;
    bottom: 0;

    .change-btn {
      cursor: pointer;
    }
  }

  .logo {
    margin-top: 40px;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  }

  .portal-list {
    transition: all 0.5s;
  }
  .portal-list-enter, .portal-list-leave-to
/* .card-leave-active for <2.1.8 */ {
    opacity: 0;
    // transform: scale(0);
    opacity: 0;
    transform: translateX(330px);
  }
  .portal-list-enter-to {
    opacity: 1;
    transform: scale(1);
  }

  .portal-list-leave-active {
    /*position: absolute;*/
  }

  .portal-list-move {
    opacity: 1;
    transition: all 0.5s;
  }

  .portal-selection-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .portal-selection-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 864px) {
    width: 100%;
  }
}

.sign-in-completed {
  width: 100%;

  .logo {
    width: 700px;
  }
}
</style>



