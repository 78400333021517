<template>
  <div class="boards-container" :class="{ light: !dark, expanded }">
    <v-tooltip
      :class="{ light: !dark }"
      right
      v-for="board in value"
      :key="board.id"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          :class="{ light: !dark }"
          class="board"
          @click="() => $emit('onSelect', board)"
          @contextmenu="(e) => show(e, board)"
        >
          <div
            v-bind="attrs"
            v-on="on"
            class="planet"
            :style="{
              '--bgColor': board.color,
            }"
            :class="{ light: !dark }"
          >
            <div class="after"></div>
            <div class="ring"></div>
          </div>
        </div>
      </template>
      <span>{{ board.name }}</span>
    </v-tooltip>

    <v-btn
      :dark="dark"
      class="collapse-icon"
      icon
      @click="expanded = !expanded"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :dark="dark"
    >
      <v-list>
        <v-list-item link @click="() => $emit('onBoardRemove', boardToRemove)">
          <v-list-item-content>
            <v-list-item-title>Remove</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon>
              <v-icon :color="'error'">mdi-trash-can-outline </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon>
              <v-icon :color="'primary'">mdi-pencil </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
  
  
  
  <script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      x: 0,
      y: 0,
      showMenu: false,
      boardToRemove: undefined,
      expanded: window.innerWidth < 1000 ? false : true,
    };
  },
  components: {},
  mounted() {},
  methods: {
    getRandom(min, max) {
      return Math.random() * (max - min) + min;
    },
    show(e, board) {
      this.boardToRemove = board;

      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.boards-container {
  position: fixed;
  max-height: 80vh;
  min-height: 200px;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 100px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba($color: #ffffff, $alpha: 0.8);
  transition: all 0.3s;
  padding-top: 50px;
  .collapse-icon {
    position: absolute;
    bottom: 0;
    right: 4px;
  }
  transform: translate(-58px, -50%);

  .board {
    transform: translate(-120px, -50%);
  }

  .collapse-icon {
    transform: rotate(180deg);
  }

  &.expanded {
    transform: translate(0px, -50%);

    .board {
      transform: translate(0px, -50%);
    }

    .collapse-icon {
      transform: rotate(0deg);
    }
  }

  @media only screen and (max-width: 1000px) {
    transform: translate(-58px, -50%);
    .board {
      transform: translate(-120px, -50%);
    }

    .collapse-icon {
      transform: rotate(180deg);
    }
  }

  &.light {
    border-right: 1px solid rgba($color: #000000, $alpha: 0.8);
  }

  &::-webkit-scrollbar {
    width: 0px;
    height: 4px;
  }

  .board {

    height: 80px;
    width: 80px;

    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      transform: translate(0px, -50%) scale(1.2);
    }

    .planet {
      top: 50%;
      left: 50%;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      // background-color: var(--bgColor);

      box-shadow: none;
      animation: none;
      transform: translate(-50%, -50%);
      background: linear-gradient(
        to bottom,
        var(--bgColor) 50%,
        transparent 100%
      );
      &.light {
        &::before {
          content: "";
          box-shadow: inset 0 0 10px rgb(0 0 0 / 50%),
            0 0 20px rgb(0 0 0 / 100%);
        }
      }

      &::before {
        content: "";
        box-shadow: inset 0 0 10px rgb(0 0 0 / 50%),
          0 0 20px rgb(255 255 255 / 50%);
        width: 40px;
        height: 40px;
        background: inherit;
        /* z-index: 9999; */
        position: absolute;
        border-radius: 50%;
      }

      .after {
        position: absolute;
        height: 26px;
        width: 40px;
        left: 4px;
        top: 0px;
        transform: rotate(30deg);
        z-index: 9;
        border-radius: 20px 20px 0 0;
        background: linear-gradient(
          to bottom,
          var(--bgColor) 0%,
          var(--bgColor) 60%,
          transparent 100%
        );
        // box-shadow: inset 0 0 10px rgb(0 0 0 / 21%),
        //   0 0 20px rgb(255 255 255 / 42%);
      }
    }

    .ring {
      top: 50%;
      left: 50%;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 5px solid #633a56;
      // border-top-color: transparent;
      transform-style: preserve-3d;

      transform: translateZ(36px) translate(-10px, -12px) rotateX(60deg)
        rotateY(30deg);
      animation: spin-ring 5s linear infinite;
      z-index: 8;

      &::after {
        content: "";
        width: 4px;
        height: 4px;
        background: black;
        position: absolute;
        border-radius: 50%;
        transform: translateZ(0px);
        top: 8px;
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
      }
    }
  }

  @keyframes spin {
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @keyframes spin-ring {
    to {
      transform: translateZ(36px) translate(-10px, -12px) rotateX(60deg)
        rotateY(30deg) rotateZ(360deg);
    }
  }
}
</style>