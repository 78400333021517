var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-in"},[_c('v-row',{staticClass:"sign-in-wrapper"},[_c('v-col',{staticClass:"pa-0 sign-in-image-col",attrs:{"cols":"12"}},[_c('div',{staticClass:"sign-in-img",class:{
          'planet-hovered': _vm.activePlanet,
        }},[_c('div',{staticClass:"sign-in-cover"})]),_c('div',{class:{
          'planet-hovered': _vm.activePlanet,
        },attrs:{"id":"planet-1"}}),_c('div',{staticClass:"clouds",class:{
          'planet-hovered': _vm.activePlanet,
        }}),_c('div',{staticClass:"overview-planet",attrs:{"id":"planet-2"},on:{"mouseleave":function($event){_vm.activePlanet = undefined},"mouseover":function($event){_vm.activePlanet = 'Overview'},"click":function($event){return _vm.onSection('overview')}}},[(_vm.activePlanet === 'Overview')?_c('span',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.activePlanet)+" ")]):_vm._e()]),_c('div',{staticClass:"overview-planet",attrs:{"id":"planet-3"},on:{"mouseleave":function($event){_vm.activePlanet = undefined},"mouseover":function($event){_vm.activePlanet = 'About'},"click":function($event){return _vm.onSection('about')}}},[(_vm.activePlanet === 'About')?_c('span',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.activePlanet)+" ")]):_vm._e()]),_c('div',{staticClass:"overview-planet",attrs:{"id":"planet-4"},on:{"mouseleave":function($event){_vm.activePlanet = undefined},"mouseover":function($event){_vm.activePlanet = 'Team'},"click":function($event){return _vm.onSection('team')}}},[(_vm.activePlanet === 'Team')?_c('span',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.activePlanet)+" ")]):_vm._e()]),_c('div',{staticClass:"overview-planet",attrs:{"id":"planet-5"},on:{"mouseleave":function($event){_vm.activePlanet = undefined},"mouseover":function($event){_vm.activePlanet = 'Settings'},"click":function($event){return _vm.onSection('settings')}}},[(_vm.activePlanet === 'Settings')?_c('span',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.activePlanet)+" ")]):_vm._e()]),_c('SignInVue',{attrs:{"minified":_vm.chatMinified,"dark":_vm.darkMode}})],1)],1),(_vm.visible)?_c('Boards',{attrs:{"value":_vm.boards,"dark":_vm.darkMode},on:{"onSelect":(board) => (_vm.activeBoard = board),"onBoardRemove":_vm.onBoardRemove}}):_vm._e(),_vm._l((_vm.boards),function(board,i){return _c('TasksBoardVue',{key:board.id,style:({
      height: '100vh',
      width: '100vw',
      transform: `translate(0, -${100 * (_vm.activeBoardIndex + 1)}vh)`,
    }),attrs:{"dark":_vm.darkMode,"visible":_vm.activeBoardIndex === i,"id":`board-${board.id}`,"board":board}})}),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.settings),callback:function ($$v) {_vm.settings=$$v},expression:"settings"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Settings ")]),_c('v-card-text',[_c('v-switch',{attrs:{"label":`Dark Mode`},model:{value:(_vm.darkMode),callback:function ($$v) {_vm.darkMode=$$v},expression:"darkMode"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.settings = false}}},[_vm._v(" Ok ")])],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }