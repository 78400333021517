import {
  drag,
  forceCollide,
  forceLink,
  forceManyBody,
  forceSimulation,
  select
} from "d3";
import * as d3 from 'd3';
import {
  d3Connections,
  d3Nodes,
  d3Drag,
  d3PanZoom,
  onTick,
  d3NodeClick
} from "./d3";


import {
  Board
} from "../Board";

import {
  event,
} from "d3";
import {
  compileAreaHTML,
  compileAreaStatsHTML
} from "../template/areaHTML";


export class MindMap {


  /**
   * 
   * @param {Board} board 
   */
  constructor(board) {
    Board

    /**
     * @type {Board}
     */
    this.board = board

    this.simulation = null;

    this.handlers = new Map();

    this.zoomLevel = 1;

    this.zoom;
  }

  subscribe(action, subscriber) {
    // let handlers = this.handlers.get(action);

    // if (!handlers)
    this.handlers.set(action, Array.isArray(subscriber) ? subscriber : [subscriber])
    // else {
    //   if (Array.isArray(subscriber))
    //     subscriber.forEach(sub => handlers.push(sub));
    //   else
    //     handlers.push(subscriber);

    // this.handlers.set(action, handlers);
    // }
  }

  emit(action, props) {
    const handlers = this.handlers.get(action);

    if (handlers)
      for (const handler of handlers) {
        handler(props)
      }


  }


  /**
   * 
   * And ID of the task that should be focused
   * 
   * @param {string} taskId 
   */
  async render(taskId) {

    let minimapScale = 0.05;

    this.scale = minimapScale;

    const compiledTasks = await Promise.all(this.board.tasks.map(task => task.compile({})))
    const compiledConnections = await Promise.all(this.board.connections.map(connection => connection.compile()))

    this.svg = select(document.getElementById(this.board.id));



    // Remove everything from SVG.
    this.svg.selectAll("*").remove();


    // Create force simulation for nodes without coordinates
    this.simulation = forceSimulation()
      .force(
        "link",
        forceLink().id(node => node.id)
        .distance(() => 2)
      )
      .force("charge", forceManyBody().strength(3))
      .force("collide", forceCollide().radius(150));

    this.initBrush(this.svg);


    // Add top-level g node
    const nodesParent = this.svg.append("g").attr("id", "mindmap-subnodes");

    // Bind data to SVG elements and set all the properties to render them
    const connectionsElements = d3Connections(nodesParent, compiledConnections);
    const tasksElements = d3Nodes(nodesParent, compiledTasks);

    tasksElements.append("title").text(node => node.name);


    // Bind nodes and connections to the simulation
    this.simulation
      .nodes(compiledTasks)
      .force("link")
      .links(compiledConnections);

    tasksElements
      .attr("class", "mindmap-node mindmap-node--editable")
      .attr("id", t => t.id)

      .on("dbclick", node => {
        node.fx = null;
        node.fy = null;
      });

    tasksElements.on("click", (d, i) => {
      this.nodeClickEvent(d3NodeClick(d, i), d);
    });

    tasksElements.call(d3Drag(this.simulation));


    // if (!this.zoom)
    this.zoom = d3PanZoom(this.svg)
      .on("zoom ", () => {
        // d3.event.sourceEvent.stopImmediatePropagation();
        this.zoomLevel = event.transform.k;


        // container.attr("transform", "translate(" + [event.transform.x, event.transform.y] + ")scale(" + event.transform.k * this.scale + ")");


        this.svg.select("#mindmap-subnodes").attr("transform", event.transform)
        this.svg.select("#selection-frame").attr("transform", event.transform)
        this.svg.select("#area-actions").attr("transform", event.transform)


        const translateData = this.getTransform(this.brush.attr('transform'));

        const transformX = parseFloat(this.brush.attr('x')) * this.zoomLevel + translateData.x +
          60 * this.zoomLevel;
        const transformY = parseFloat(this.brush.attr('y')) * this.zoomLevel + translateData.y +
          (parseFloat(this.brush.attr('height')) + 60) * this.zoomLevel

        this.svg.select("#bulk-stats")
          .attr('transform', `translate(${transformX},${transformY}) scale(${
        this.zoomLevel 
      })`);


        this.minimap.select('#minimap-frame').remove();


        let mapWidth = parseFloat(this.svg.style('width'));
        let mapHeight = parseFloat(this.svg.style('height'));

        // let factor = mapWidth / this.svg.attr('viewBox').split(' ')[2]

        // let dx = d3.event.transform.x / d3.event.transform.k;
        // let dy = d3.event.transform.y / d3.event.transform.k;




        this.minimap.select('.minimap')
          .attr('transform', `translate(${(mapWidth)* this.scale},${(mapHeight)* this.scale}) scale(${
            this.scale 
          })`);



        this.minimap.append('rect')
          .attr('id', 'minimap-frame')
          .attr('width', mapWidth / d3.event.transform.k)
          .attr('height', mapHeight / d3.event.transform.k)
          .attr('stroke-width', 10)
          .attr('rx', 95)
          .attr('fill', 'none')
          .attr('transform', `translate(${(mapWidth)* this.scale   + -this.scale * d3.event.transform.x / d3.event.transform.k },${(mapHeight)* this.scale + -this.scale * d3.event.transform.y / d3.event.transform.k}) scale(${ this.scale})`);

      });



    this.svg
      .attr("viewBox", `0 0 ${document.getElementById(this.board.id).clientWidth } ${document.getElementById(this.board.id).clientHeight}`)
      .call(this.zoom)




    const minimapSvg = select(document.getElementById(`${this.board.id}-minimap`))
    minimapSvg.selectAll(".minimap").remove();

    this.minimap = minimapSvg;

    minimapSvg.selectAll('*').remove();



    const container = minimapSvg.append("g")
      .attr("class", "minimap")
      .attr("viewBox", `0 0 ${document.getElementById(this.board.id).clientWidth * 0.2 } ${document.getElementById(this.board.id).clientHeight * 0.2}`)

    this.frameX = 0;
    this.frameY = 0;

    this.container = container;

    const frameDrag = drag()
      .on("drag", () => {
        // d3.event.sourceEvent.stopImmediatePropagation();

        this.frameX += d3.event.dx;
        this.frameY += d3.event.dy;

      })

    minimapSvg
      // .attr("viewBox", `0 0 384 216`)
      .attr("width", document.getElementById(this.board.id).clientWidth * 0.2)
      .attr("height", document.getElementById(this.board.id).clientHeight * 0.2)
      .attr("viewBox", `0 0 ${document.getElementById(this.board.id).clientWidth * 0.2} ${document.getElementById(this.board.id).clientHeight * 0.2}`)
      .call(frameDrag)
    // .call(zoom);




    for (let i = 0; i < 10; i += 1) {
      this.simulation
        .force("x", d3.forceX())
        .force("y", d3.forceY())
        .tick();
    }


    const connectionsElements1 = d3Connections(container, compiledConnections);
    const tasksElements1 = d3Nodes(container, compiledTasks);


    let focusTask = compiledTasks[compiledTasks.length - 1]

    if (taskId && typeof taskId === 'string') {
      focusTask = compiledTasks.find(t => t.id === taskId)
    } else if (taskId && typeof taskId === 'object') {
      focusTask = taskId
    }

    this.svg
      .transition()
      .call(this.zoom.scaleTo, this.zoomLevel || 1)
    focusTask
    this.svg
      .transition()
      .duration(1000)
      .call(this.zoom.translateTo,
        focusTask.x,
        focusTask.y
      )




    // minimapSvg
    //   .transition()
    //   .duration(1000)
    //   .call(zoom.translateTo,
    //     compiledTasks[compiledTasks.length - 1].x,
    //     compiledTasks[compiledTasks.length - 1].y
    //   )

    // minimapSvg
    //   .transition()
    //   .call(zoom.scaleTo, minimapScale)


    for (let i = 0; i < 100; i += 1) {
      this.simulation
        .force("x", d3.forceX())
        .force("y", d3.forceY())
        .tick();
    }


    // connectionsElements1


    this.simulation.on("tick", () => {
      onTick(
        connectionsElements,
        tasksElements
      )

      onTick(
        connectionsElements1,
        tasksElements1
      )
    });

  }



  initBrush() {

    const svgElement = document.getElementById(this.board.id)

    this.svg.selectAll('#selection-frame').remove();
    this.svg.selectAll('#area-actions').remove();


    this.brush = this.svg
      .append('rect')
      .attr('id', 'selection-frame')
      .attr('width', 0)
      .attr('height', 0)
      .attr('x', 0)
      .attr('y', 0)
      .attr('stroke-width', 10)

    document.addEventListener('keydown', (event) => {
      if (event.code === 'ControlLeft')
        this.crtClicked = true
    })
    document.addEventListener('keyup', () => {
      this.crtClicked = false
    })

    svgElement.addEventListener('mousedown', (e) => {
      if (this.crtClicked) {
        this.svg.selectAll('#area-actions').remove();
        this.svg.select('#bulk-stats').remove();

        this.originalEventX = e.x;
        this.originalEventY = e.y;
        this.lcClicked = true
      }

    })
    svgElement.addEventListener('mouseup', () => {


      if (this.originalEventX && this.originalEventY) {

        this.svg
          .append("foreignObject")
          .attr("id", "area-actions")
          .attr("width", 20)
          .attr("height", 20)
          .attr('x', this.brush.attr('x'))
          .attr('y', this.brush.attr('y'))
          .attr('transform', this.brush.attr('transform'))
          .html(() => compileAreaHTML())
          .on("click", (d, i) => {
            this.areaClickEvent(d3NodeClick(d, i), d);
          });

        this.addChart(this.svg, this.brush.attr('x'), this.brush.attr('y'), this.brush.attr('transform'))

        this.originalEventX = undefined;
        this.originalEventY = undefined;
        this.lcClicked = false

      }

    })

    this.svg.on('mousemove', () => {

      if (!this.lcClicked || !this.crtClicked)
        return

      const {
        x,
        y
      } = this.getTransform(this.brush.attr('transform'));


      const originX = -x / this.zoomLevel + this.originalEventX / this.zoomLevel
      const originY = -y / this.zoomLevel + this.originalEventY / this.zoomLevel

      const newX = (-x / this.zoomLevel + event.x / this.zoomLevel);
      const newY = (-y / this.zoomLevel + event.y / this.zoomLevel)


      const changeX = newX - originX;
      const changeY = newY - originY;



      this.brush
        .attr('x', changeX > 0 ? originX : (-x / this.zoomLevel + event.x / this.zoomLevel))
        .attr('y', changeY > 0 ? originY : (-y / this.zoomLevel + event.y / this.zoomLevel))
        .attr('width', Math.abs(changeX))
        .attr('height', Math.abs(changeY))


    })



    svgElement.addEventListener('mousemove', (e) => {
      if (this.lcClicked && this.crtClicked)
        e.preventDefault();


    })
  }


  addChart(svg) {

    const selectedTasks = this.board.getSelectedTasks({
      x: this.brush.attr('x'),
      y: this.brush.attr('y'),
      width: this.brush.attr('width'),
      height: this.brush.attr('height')
    })


    const data = this.board.statsByStatus(selectedTasks)
    const data2 = this.board.statsByPriority(selectedTasks)


    svg.select('#bulk-stats').remove();


    this.bulkStats = svg.append("g")
      .attr('id', 'bulk-stats')
      .attr('width', 200)
      .attr('height', 100)


    this.bulkStats
      .append("foreignObject")
      .attr("id", "area-stats")
      .attr("width", 20)
      .attr("height", 20)
      .attr('x', 0)
      .attr('y', 0)
      .attr('transform', "translate(" + -60 + "," + -80 + ")")
      .html(() => compileAreaStatsHTML(selectedTasks.length))
    // .on("click", (d, i) => {
    //   this.areaClickEvent(d3NodeClick(d, i), d);
    // });

    // .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    const g = this.bulkStats
      .append("g")
      .attr('id', 'status-pie')
      .attr('class', 'area-pie')
      .attr('width', 100)
      .attr('height', 100)

    const g2 = this.bulkStats
      .append("g")
      .attr('id', 'priority-pie')
      .attr('class', 'area-pie')
      .attr('width', 100)
      .attr('height', 100)
      .attr('transform', 'translate(120,0)')




    this.drawChart(data, g)
    this.drawChart(data2, g2)






    const translateData = this.getTransform(this.brush.attr('transform'));

    const transformX = parseFloat(this.brush.attr('x')) * this.zoomLevel + translateData.x +
      60 * this.zoomLevel;
    // + parseFloat(this.brush.attr('width')) * this.zoomLevel - 25
    const transformY = parseFloat(this.brush.attr('y')) * this.zoomLevel + translateData.y +
      (parseFloat(this.brush.attr('height')) + 60) * this.zoomLevel

    this.bulkStats
      .attr('transform', `translate(${transformX},${transformY}) scale(${
        this.zoomLevel 
      })`);

  }






  drawChart(data, g) {
    const radius = 50;


    var ordScale = d3.scaleOrdinal()
      .domain(data)
      .range(['#6A1B9A', '#E91D63', '#0097A7', '#3949AB', '#E65100']);

    var pie = d3.pie().value((d) => {
      return d.share;
    });

    var arc = g.selectAll("arc")
      .data(pie(data))
      .enter();


    var path = d3.arc()
      .outerRadius(radius)
      .innerRadius(0);

    arc.append("path")
      .attr("d", path)
      .attr("fill", (d) => {
        return ordScale(d.data.name);
      });

    var label = d3.arc()
      .outerRadius(radius)
      .innerRadius(0);

    arc.append("text")
      .attr("transform", function (d) {

        return "translate(" + (label.centroid(d)[0] - 20) + ',' + label.centroid(d)[1] + ")";
      })
      .text(function (d) {
        return `${d.data.name} (${d.data.share})`;
      })
      .style("font-family", "Kanit")
      .style("font-size", 10);
  }


  getTransform(string) {
    const match = String(string).match(/translate\(([0-9]|,|-|\.)*\)/)
    let x = 0;
    let y = 0;
    if (match) {
      const [strX, strY] = match[0].slice(10, match[0].length - 1).split(',')
      x = parseFloat(strX)
      y = parseFloat(strY)
    }


    return {
      x,
      y
    }
  }



  /**
   * node mouse click events
   */
  nodeClickEvent({
    action,
    event
  }, task) {
    switch (action) {
      case "add":
        this.emit('onTaskAdd', {
          task,
          event
        });
        break;
      case "edit":
        this.emit('onTaskEdit', {
          task,
          event
        });
        break;
      case "remove":
        this.emit('onTaskRemove', {
          task,
          event
        });
        break;
      case "click":
        this.emit('onTaskClicked', {
          task,
          event
        });
        break;

      case "link":
        this.emit('onTaskLink', {
          task,
          event
        });
        break;
    }
  }



  /**
   * node mouse click events
   */
  areaClickEvent({
    action,
    event
  }, task) {
    switch (action) {
      case "add":
        this.emit('onTaskAdd', {
          task,
          event
        });
        break;
      case "edit":
        this.emit('onTaskEdit', {
          task,
          event
        });
        break;
      case "remove":
        this.svg.selectAll('#selection-frame').attr('width', 0).attr('height', 0);
        this.svg.selectAll('#area-actions').remove();
        this.svg.select('#bulk-stats').remove();


        this.emit('onAreaRemove', {
          task,
          event
        });
        break;
      case "click":
        this.emit('onTaskClicked', {
          task,
          event
        });
        break;

      case "link":
        this.emit('onTaskLink', {
          task,
          event
        });
        break;
    }
  }

}