import {
    v4 as uuidv4
} from "uuid";
// import uniqolor from 'uniqolor';

import {
    compileHTML
} from './template/taskHTML';
import {
    getDimensions
} from './utils/dimensions'

export class Task {


    constructor({
        id,
        name,
        color,
        description,
        priority,
        status,
        assignee,
        images,
        d3Node,
        simpleId,
        settings
    } = {}) {
        if (!name)
            throw new Error('Task name is required!')

        this.id = id || uuidv4();
        this.color = color || '#FFFFFF';
        this.simpleId = simpleId;


        this.name = name;
        this.description = description;
        this.priority = priority;
        this.status = status;
        this.assignee = assignee;
        this.images = images || []

        this.d3Node = d3Node || {
            id: this.id
        }


        this.settings = settings || Task.DefaultSettings

    }


    static get DefaultSettings() {
        return {
            keepParentColor: false,

            NESTED_UPDATE_StatusChangeProtection: true,
            NESTED_UPDATE_ColorChangeNestedProtection: false,
            BULK_UPDATE_StatusChangeProtection: true,
            BULK_UPDATE_ColorChangeNestedProtection: false,

        }
    }




    toJSON() {

        return {
            id: this.id,
            name: this.name,
            simpleId: this.simpleId,
            color: this.color,
            description: this.description,
            status: this.status,
            priority: this.priority,
            assignee: this.assignee,
            settings: this.settings,
            images: this.images.map(img => ({
                ext: img.ext,
                lastModified: img.lastModified,
                name: typeof img.name == 'function' ? img.name() : img.name,
                size: img.size,
                sizeText: img.sizeText,
                src: typeof img.src == 'function' ? img.src() : img.src,
                type: img.type,
                url: typeof img.url == 'function' ? img.url() : img.url,
                urlResized: img.urlResized
            })),
            d3Node: this.d3Node
        }
    }




    async compile({
        ignoreXY = false
    } = {}) {
        const d3Node = {
            ...this.d3Node
        }


        d3Node.html = await compileHTML(this);

        const dimensions = getDimensions(d3Node.html, {}, "mindmap-node");

        d3Node.width = dimensions.width;
        d3Node.height = dimensions.height;

        if (ignoreXY) {
            delete d3Node.x
            delete d3Node.y
            delete d3Node.fy
            delete d3Node.fx
        }



        this.d3Node = d3Node;

        return d3Node;
    }
}