<template>
  <div class="sign-in">
    <v-row class="sign-in-wrapper">
      <v-col class="pa-0 sign-in-image-col" cols="12">
        <div
          class="sign-in-img"
          :class="{
            'planet-hovered': activePlanet,
          }"
        >
          <div class="sign-in-cover"></div>
        </div>
        <div
          id="planet-1"
          :class="{
            'planet-hovered': activePlanet,
          }"
        ></div>
        <div
          class="clouds"
          :class="{
            'planet-hovered': activePlanet,
          }"
        ></div>
        <div
          @mouseleave="activePlanet = undefined"
          @mouseover="activePlanet = 'Overview'"
          id="planet-2"
          class="overview-planet"
          @click="onSection('overview')"
        >
          <span v-if="activePlanet === 'Overview'" class="description">
            {{ activePlanet }}
          </span>
        </div>

        <div
          @mouseleave="activePlanet = undefined"
          @mouseover="activePlanet = 'About'"
          id="planet-3"
          class="overview-planet"
          @click="onSection('about')"
        >
          <span v-if="activePlanet === 'About'" class="description">
            {{ activePlanet }}
          </span>
        </div>
        <div
          @mouseleave="activePlanet = undefined"
          @mouseover="activePlanet = 'Team'"
          id="planet-4"
          class="overview-planet"
          @click="onSection('team')"
        >
          <span v-if="activePlanet === 'Team'" class="description">
            {{ activePlanet }}
          </span>
        </div>

        <div
          @mouseleave="activePlanet = undefined"
          @mouseover="activePlanet = 'Settings'"
          id="planet-5"
          class="overview-planet"
          @click="onSection('settings')"
        >
          <span v-if="activePlanet === 'Settings'" class="description">
            {{ activePlanet }}
          </span>
        </div>

        <!-- <router-view></router-view> -->
        <SignInVue :minified="chatMinified" :dark="darkMode"></SignInVue>
      </v-col>
    </v-row>
    <Boards
      v-if="visible"
      :value="boards"
      @onSelect="(board) => (activeBoard = board)"
      @onBoardRemove="onBoardRemove"
      :dark="darkMode"
    ></Boards>
    <TasksBoardVue
      :dark="darkMode"
      v-for="(board, i) in boards"
      :key="board.id"
      :visible="activeBoardIndex === i"
      :id="`board-${board.id}`"
      :board="board"
      :style="{
        height: '100vh',
        width: '100vw',
        transform: `translate(0, -${100 * (activeBoardIndex + 1)}vh)`,
      }"
    ></TasksBoardVue>

    <v-dialog v-model="settings" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Settings </v-card-title>
        <v-card-text>
          <v-switch v-model="darkMode" :label="`Dark Mode`"></v-switch
        ></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="settings = false"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import TasksBoardVue from "../components/wad/molecules/tasks/TasksBoard.vue";
import { BotInstance } from "./bot";
import SignInVue from "./SignIn.vue";
import Boards from "../components/wad/molecules/tasks/Boards.vue";
import { Storage } from "../components/wad/molecules/tasks/lib/Storage";

export default {
  data() {
    const st = new Storage();

    const darkMode = localStorage.getItem("darkMode");

    console.log("darkMode", darkMode);

    return {
      st,
      visible: false,
      chatMinified: false,
      activePlanet: undefined,

      boards: [],

      activeBoard: undefined,
      settings: false,
      darkMode: darkMode === null ? true : darkMode === "true" ? true : false,
    };
  },
  computed: {
    activeBoardIndex: function () {
      return this.activeBoard
        ? this.boards.findIndex((br) => br.id === this.activeBoard.id)
        : -1;
    },
  },
  components: {
    TasksBoardVue,
    SignInVue,
    Boards,
  },
  created() {
    BotInstance.subscribe("onSignIn", () => {
      console.log("OnSignIN");

      this.boards = this.st.load().boards;
      console.log("OnSignIN", this.boards);
      
      this.st.startSaving();

      setTimeout(() => {
        this.activeBoard = this.boards[0];
        this.visible = true;
        this.chatMinified = true;
      }, 1000);
    });

    BotInstance.subscribe("onSignOut", () => {
      setTimeout(() => {
        this.activeBoard = undefined;
        this.visible = false;
        this.chatMinified = false;
      }, 1000);
    });

    BotInstance.subscribe("onSettings", () => {
      this.settings = true;
    });

    BotInstance.subscribe("onBoardCreation", ({ props }) => {
      const newBoard = this.st.addBoard(props.name || "New Board");

      console.log('newBoard: ', newBoard)
      console.log('newBoard: ', this.boards);

      // setTimeout(() => {
      //   this.activeBoard = newBoard;
      // }, 1000);
    });
  },
  methods: {
    onSection(section) {
      BotInstance.fakeMessage({
        command: section,
      });
    },
    onBoardRemove(board) {
      const index = this.st.dropBoard(board);

      if (this.activeBoard.id === board.id) {
        this.activeBoard = this.boards[index - 1];
      }
    },
  },
  watch: {
    darkMode(newVal) {
      localStorage.setItem("darkMode", newVal);
    },
  },
};
</script>
  
  
  
  <style lang="scss" scoped>
#adaas_sso {
  display: none;
}
.sign-in {
  background: #000;
  height: 100%;
  max-height: 100%;
  width: 100%;
  // overflow: scroll;
  overflow: hidden;
  // z-index: 4;
  .sign-in-wrapper {
    height: 100%;
    width: 100%;
    margin: 0px !important;
    // z-index: 9;
  }

  .sign-in-image-col {
    // -webkit-animation: spinX 3s infinite;
    // z-index: 9;
    overflow: hidden;
    position: relative;
    .sign-in-img {
      position: absolute;
      opacity: 1;
      transition: all 0.5s;

      &.planet-hovered {
        opacity: 0.3;
      }

      left: 0;
      bottom: 0;
      // top: -50vh;
      // left: -50vh;
      width: 100%;
      height: 100%;
      // background-image: url("/background-5.png");
      background-image: url("/backgrounds/6.jpg");
      background-size: cover;
      background-position: 10%, 50%;

      &::after {
        content: " ";
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba($color: #000000, $alpha: 0.6);
        top: 0;
        left: 0;
      }
      .sign-in-cover {
        height: 100%;
        max-height: 100%;
        width: 100%;
      }
    }
  }
}
</style>
  