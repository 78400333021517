<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6">
        <HelpFormInputVue
          v-model="form.name"
          :label="'Task name'"
        ></HelpFormInputVue>

        <SelectorDefaultVue
          v-model="form.priority"
          :label="'Task Priority'"
          :items="priorities"
          return-object
          :dark="dark"
        ></SelectorDefaultVue>
        <SelectorDefaultVue
          v-model="form.status"
          :label="'Task Status'"
          :items="statuses"
          return-object
          :dark="dark"
        ></SelectorDefaultVue>

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="form.dueDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :dark="dark"
        >
          <template v-slot:activator="{ attrs, on }">
            <!-- <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Dropdown
        </v-btn> -->
            <v-combobox
              v-model="form.dueDate"
              chips
              small-chips
              label="Due Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-combobox>
          </template>
          <v-date-picker v-model="form.dueDate" multiple no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(form.dueDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <HelpFormInputVue
          textarea
          v-model="form.description"
          :label="'Task Description'"
        ></HelpFormInputVue>
        <VueFileAgent
          :theme="'list'"
          ref="vueFileAgent"
          :deletable="false"
          multiple
          :accept="'image/*'"
          :maxSize="'10MB'"
          :maxFiles="14"
          :helpText="'Choose images or zip files'"
          @input="onFileInput"
          :errorText="{
            type: 'Invalid file type. Only images  Allowed',
            size: 'Files should not exceed 10MB in size',
          }"
          v-model="form.images"
        ></VueFileAgent>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="12">
        <v-color-picker
          class="ma-2"
          show-swatches
          v-model="color"
          :dark="dark"
        ></v-color-picker>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="12">
        <v-switch
          v-model="form.settings.NESTED_UPDATE_StatusChangeProtection"
          :label="
            form.settings.NESTED_UPDATE_StatusChangeProtection
              ? 'Status is independent from parent. '
              : 'Status will be changed in sync with parent. '
          "
        ></v-switch>
        <v-switch
          v-model="form.settings.NESTED_UPDATE_ColorChangeNestedProtection"
          :label="
            form.settings.NESTED_UPDATE_ColorChangeNestedProtection
              ? 'Color is independent from parent. '
              : 'Color will be changed in sync with parent. '
          "
        ></v-switch>
        <v-switch
          v-model="form.settings.BULK_UPDATE_StatusChangeProtection"
          :label="
            form.settings.BULK_UPDATE_StatusChangeProtection
              ? 'Status is independent from parent. (Bulk Operations)'
              : 'Status will be changed in sync with parent. (Bulk Operations)'
          "
        ></v-switch>
        <v-switch
          v-model="form.settings.BULK_UPDATE_ColorChangeNestedProtection"
          :label="
            form.settings.BULK_UPDATE_ColorChangeNestedProtection
              ? 'Color is independent from parent. (Bulk Operations)'
              : 'Color will be changed in sync with parent. (Bulk Operations)'
          "
        ></v-switch>
      </v-col>
    </v-row>
  </v-form>
</template>
          
          
    <script>
import SelectorDefaultVue from "../../atoms/common/autocompletes/SelectorDefault.vue";
import HelpFormInputVue from "../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const form = this.value;
    form.images =
      this.value.images && this.value.images.length ? this.value.images : [];

    return {
      // Form
      pValid: this.valid,
      form,
      color: this.value.color || "#ffffff",

      images: [],
      menu: false,

      priorities: [
        {
          icon: "mdi-chevron-double-up",
          value: "highest",
          name: "Highest",
          iconColor: "red",
        },
        {
          name: "High",
          value: "high",
          icon: "mdi-chevron-up",
          iconColor: "orange",
        },
        {
          name: "Medium",
          value: "medium",
          icon: "mdi-tilde",
          iconColor: "yellow",
        },
        {
          name: "Low",
          value: "low",
          icon: "mdi-chevron-down",
          iconColor: "success",
        },
      ],

      statuses: [
        {
          name: "TODO",
          value: "todo",
          icon: "mdi-send-outline",
          iconColor: "#B0BEC5",
        },
        {
          name: "In Progress",
          value: "in_progress",
          icon: "mdi-sync",
          iconColor: "#2E7D32",
        },
        {
          name: "Blocked",
          value: "blocked",
          icon: "mdi-cancel",
          iconColor: "#FF6D00",
        },
        {
          name: "Outdated",
          value: "outdated",
          icon: "mdi-timer-off-outline",
          iconColor: "#37474F",
        },
        {
          name: "Done",
          value: "done",
          icon: "mdi-check-decagram-outline",
          iconColor: "#7C4DFF",
        },
        {
          name: "Failed",
          value: "failed",
          icon: "mdi-close",
          iconColor: "#DD2C00",
        },
      ],

      ages: [],

      // ======rules
      nameRules: [(v) => !!v || "Task name is required"],
      descriptionRules: [(v) => !!v || "Description is required"],

      parentRules: [
        (v) => (v && v.length ? true : "At least one category is required"),
        (v) => v.length <= 3 || "You can select maximum 3 category at once",
      ],

      interestRules: [
        (v) => (v && v.length ? true : "At least one tag is required"),
        (v) => v.length <= 10 || "You can select maximum 20 category tags.",
      ],
    };
  },
  computed: {},
  components: {
    HelpFormInputVue,
    SelectorDefaultVue,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
      this.form = {};
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onFileInput(files) {
      console.log("files: ", files);
    },
    // onBeforeDelete: function (fileRecord) {
    //   var i = this.form.images.indexOf(fileRecord);
    //   if (i !== -1) {
    //     this.form.images.splice(i, 1);
    //   } else {
    //     if (confirm("Are you sure you want to delete?")) {
    //       this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
    //     }
    //   }
    // },
    // fileDeleted: function (fileRecord) {
    //   var i = this.form.images.indexOf(fileRecord);
    //   if (i !== -1) {
    //     this.form.images.splice(i, 1);
    //   } else {
    //     this.deleteUploadedFile(fileRecord);
    //   }
    // },
  },
  watch: {
    value(newVal) {
      console.log("VALUE CHANGED: ", newVal.taskName);
      console.log("VALUE CHANGED: ", newVal.images);

      this.form = newVal;
      this.form.images =
        newVal.images && newVal.images.length ? newVal.images : [];
      this.color = newVal.color || "#ffffff";
      // this.images = newVal.images || [];
    },
    form: {
      handler(newVal) {
        console.log("NewVal: ", JSON.stringify(newVal.images));
        // this.$emit("input", newVal);
      },
      deep: true,
    },
    color: {
      handler(newVal) {
        console.log("color: ", newVal);
        if (typeof newVal === "object") this.form.color = newVal.hexa;
        else this.form.color = newVal;
      },
      deep: true,
    },
    "form.images": {
      handler(newVal) {
        console.log("NEW IMAGES RECEIVED", newVal);

        // this.form.images = newVal && newVal.length ? [...newVal] : [newVal];
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
          
          
      <style lang="scss" scoped>
.answers-container {
  transition: all 0.5s ease;
}
.answer-item {
  border-radius: 14px;
  border-color: #ebbf00;

  border-bottom: 1px solid;

  &:hover::before {
    border-radius: 14px !important;
  }
}
</style>