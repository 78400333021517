export const compileAreaHTML = () => {
    return `
  
            <div class="area-actions">
                <div class="action edit" title="Edit task"><i class="mdi mdi-square-edit-outline"></i></div>
                <div class="action remove" title="Remove task"><i class="mdi mdi-trash-can-outline"></i></div>

            </div>
    `;               
}


// <div class="action new" title="Create new task"><i class="mdi mdi-plus-box-outline"></i></div>
// 
// <div class="action link" title="Link task"><i class="mdi mdi-link-box-variant-outline"></i></div>


export const compileAreaStatsHTML = (total = 0) => {

    return `
  
            <div class="area-stats">
                <div class="stat-item total" title="Total Selected"> Selected: ${total}</div>
            </div>
    `;
}